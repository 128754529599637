/* ----------------------------
   home.js
---------------------------- */

$(function(){
	/* ----- Main Visual ----- */
	function mainvisual(){
		var _target = $('#mainVisual .main');
		//var _stage = $('.stage',_target);
		var _display = $('.disp ul',_target);
		var _text = $('.text ul',_target);
		var _control = {
			prev : $('.control .prev',_target),
			next : $('.control .next',_target)
		}
		var _speed = 700;
		var _delay = 5000;
		
		var itemWidth;
		var textHeight;
		var timer;
		
		var flag = 0;
		
		function init(){
			itemWidth = $('li:first',_display).width();
			textHeight = $('li:first',_text).height();
			auto();
			clickEvent();
			
			_target.mouseover(function(){
				flag = 1;
				clearTimeout(timer);
			}).mouseleave(function(){
				flag = 0;
				auto();
			});
		}
		
		function clickEvent(){
			_control.prev.click(function(){
				clearTimeout(timer);
				move(-1);
				return false;
			});
			
			_control.next.click(function(){
				clearTimeout(timer);
				move(1);
				return false;
			});
		}
		
		function move(d){
			
			d = d *-1;
			
			_control.prev.unbind('click');
			_control.next.unbind('click');
			clearTimeout(timer);

			if(d > 0){
				/* Prev */
				$('li:last',_display).prependTo(_display);
				_display.css({
					left: -itemWidth
				});
				_display.animate({
					left: 0
				},_speed,'easeOutExpo',function(){
					clickEvent();
					auto();
				});
				
				$('li:last',_text).prependTo(_text);
				_text.css({
					top: -textHeight
				});
				
				_text.animate({
					top: 0
				},_speed,'easeOutExpo');
				
			} else {
				/* Next */
				_display.animate({
					left: -itemWidth
				},_speed,'easeOutExpo',function(){
					$('li:first',_display).appendTo(_display);
					_display.css({
						left: 0
					});
					clickEvent();
					auto();
				});
				
				_text.animate({
					top: -textHeight
				},_speed,'easeOutExpo',function(){
					$('li:first',_text).appendTo(_text);
					_text.css({
						top: 0
					});
				});
				
			}
		}
		
		function auto(){
			if(flag == 0){
				timer = setTimeout(function(){
					move(1);
				},_delay);
			}
		}
		
		init();
		
	}
	
	mainvisual();
	
	/* ----- Topics ----- */
	function topics(){
		var _target = $('#topicsArea .topics');
		var _text = $('.text',_target);
		var _switch = $('.switch',_target);
		var _height = _target.height();
		var _delay = 5000;
		var _speed = 700;
		
		var _maxLength = 5;
		
		var timer;
			
		function init(){
			for(var i = 0; i < _text.find('li').length; i++){
				_switch.append('<li>' + i + '</li>');
			}
			$('li:first',_switch).addClass('selected');
			if($('li',_text).length > 1){
				auto();
			}
		}
		
		function auto(){
			timer = setTimeout(function(){
				move();
			},_delay);
		}
				
		function move(){
			if($('.selected',_switch).index() <	$('li',_switch).length - 1){
				$('.selected',_switch).removeClass('selected').next().addClass('selected');
			} else {
				$('.selected',_switch).removeClass('selected');
				$('li:first',_switch).addClass('selected');
			}
			_text.animate({
				top: -_height
			},_speed,'easeOutExpo',function(){
				$('li:first',this).remove().appendTo(_text).parent().removeAttr('style');
				auto();
			});
		}
		
		init();
	}
	
	topics();
	
	/* ----- Side : Specialshop Topics ----- */
	function spshopTopics(){
		var _target = $('#sideSpshopTopics .articles');
		var _delay = 2500;
		var _speed = 700;
		var flag = 0;
		var timer;
		//var _width = $('li:first',_target).width();

		function move(){
			flag = 1;
			_target.each(function() {
				$(this).animate({
					'left': -300
				},_speed,'easeOutExpo',function(){
					$('li:first',this).appendTo(_target);
					$(this).css('left',0);
					flag = 0;
					auto();		
				});
			})
		}
		
		function auto(){
			if(flag == 0){
				timer = setTimeout(function(){
					move();
				},_delay);
			}
		}
		
		$('a',_target).mouseover(function(){
			if(flag == 0){
				flag = 1;
				$(_target).stop(true,true);
				clearTimeout(timer);
			}
		});
		
		$('a',_target).mouseleave(function(){
			if(flag == 1) {
				flag = 0;
				$(_target).stop(true, true);
				clearTimeout(timer);
				auto();
			}
		});
		
		auto();
		
	}
	
	spshopTopics();

	/* ----- Side : Specialshop Topics ----- */
	function sideNintei(){
		var _target = $('#sideNintei .articles');
		var _delay = 2300;
		var _speed = 900;
		var flag = 0;
		var timer;
		//var _width = $('li:first',_target).width();

		function move(){
			flag = 1;
			_target.each(function() {
				$(this).animate({
					'left': -300
				},_speed,'easeOutExpo',function(){
					$('li:first',this).appendTo(_target);
					$(this).css('left',0);
					flag = 0;
					auto();		
				});
			})
		}
		
		function auto(){
			if(flag == 0){
				timer = setTimeout(function(){
					move();
				},_delay);
			}
		}
		
		$('a',_target).mouseover(function(){
			if(flag == 0){
				flag = 1;
				$(_target).stop(true,true);
				clearTimeout(timer);
			}
		});
		
		$('a',_target).mouseleave(function(){
			if(flag == 1) {
				flag = 0;
				$(_target).stop(true, true);
				clearTimeout(timer);
				auto();
			}
		});
		
		if($("li", _target).length > 1) {
			auto();
		}
		
	}
	
	sideNintei();
	
	function sideHomeBanner(){
		var _target = $('#rotationBanner ul');
		var _delay = 2100;
		var _speed = 900;
		var flag = 0;
		var timer;

		function move(){
			flag = 1;
			_target.each(function() {
				$(this).animate({
					'left': -300
				},_speed,'easeOutExpo',function(){
					$('li:first',this).appendTo(_target);
					$(this).css('left',0);
					flag = 0;
					auto();		
				});
			})
		}
		
		function auto(){
			if(flag == 0){
				timer = setTimeout(function(){
					move();
				},_delay);
			}
		}
		
		$('a',_target).mouseover(function(){
			if(flag == 0){
				flag = 1;
				$(_target).stop(true,true);
				clearTimeout(timer);
			}
		});
		
		$('a',_target).mouseleave(function(){
			if(flag == 1) {
				flag = 0;
				$(_target).stop(true, true);
				clearTimeout(timer);
				auto();
			}
		});
		
		if($("li", _target).length > 1) {
			auto();
		}
		
	}
	
	sideHomeBanner();
	
});